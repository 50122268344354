import React from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import './AboutUsBody.scss';

const AboutUsBody = ({ data }) => (
  <div className="after-us-body">
    <ContentBox>
      <div className="row">
        <div className="col-md-5 col-sm-12">
          <div className="after-us-body__img">
            <img
              className="after-us-body__img-item"
              src={data.image.publicURL}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div
            className="after-us-body__text"
            dangerouslySetInnerHTML={{
              __html: data.descriptionLine1,
            }}
          />
        </div>
      </div>
    </ContentBox>
  </div>
);

export default AboutUsBody;
