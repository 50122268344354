import React, { Fragment } from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import './AboutUsAfterBody.scss';

const BulletPoints = ({ data }) => {
  if (data.bulletPoints) {
    return data.bulletPoints.map((bp, i) => {
      return <li key={`auab-bullet-${i}`}>{bp.content}</li>;
    });
  }
  return null;
};

const Description = ({ data }) => {
  if (data.description) {
    return <p className="about-us-after-body__text">{data.description}</p>;
  }
  return null;
};

const AboutUsAfterBody = ({ data }) => (
  <div className="about-us-after-body">
    <ContentBox>
      <h1 className="about-us-after-body__title">{data.title}</h1>
      <h2 className="about-us-after-body__subtitle">{data.subtitle}</h2>
      {data.description ? (
        <Fragment>
          <hr />
          <Description data={data} />
          <ul>
            <BulletPoints data={data} />
          </ul>
        </Fragment>
      ) : null}
    </ContentBox>
  </div>
);

export default AboutUsAfterBody;
