import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import AboutUsBody from 'components/AboutUsBody/AboutUsBody';
import AboutUsAfterBody from 'components/AboutUsAfterBody/AboutUsAfterBody';
import PreFooter from 'components/PreFooter/PreFooter';

const AboutPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO title={data.aboutYaml.title} />
      <Hero data={data.aboutYaml.hero} />
      <AboutUsBody data={data.aboutYaml.aboutUsBody} />
      <AboutUsAfterBody data={data.aboutYaml.aboutUsAfterBody} />
      <PreFooter />
      <ZipGateContainer location={location} />
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPageQuery {
    aboutYaml {
      id
      title

      hero {
        size
        title
        subtitle
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        separator
      }

      aboutUsBody {
        image {
          publicURL
        }
        descriptionLine1
      }

      aboutUsAfterBody {
        title
        subtitle
      }
    }
  }
`;
